export const MAIN_COUNTRY_LIST_REQUEST = 'MAIN_COUNTRY_LIST_REQUEST'
export const MAIN_COUNTRY_LIST_SUCCESS = 'MAIN_COUNTRY_LIST_SUCCESS'
export const MAIN_COUNTRY_LIST_FAIL = 'MAIN_COUNTRY_LIST_FAIL'
export const MAIN_COUNTRY_LIST_RESET = 'MAIN_COUNTRY_LIST_RESET'

export const EU_COUNTRY_LIST_REQUEST = 'EU_COUNTRY_LIST_REQUEST'
export const EU_COUNTRY_LIST_SUCCESS = 'EU_COUNTRY_LIST_SUCCESS'
export const EU_COUNTRY_LIST_FAIL = 'EU_COUNTRY_LIST_FAIL'
export const EU_COUNTRY_LIST_RESET = 'EU_COUNTRY_LIST_RESET'

export const MADICINE_COUNTRY_LIST_REQUEST = 'MADICINE_COUNTRY_LIST_REQUEST'
export const MADICINE_COUNTRY_LIST_SUCCESS = 'MADICINE_COUNTRY_LIST_SUCCESS'
export const MADICINE_COUNTRY_LIST_FAIL = 'MADICINE_COUNTRY_LIST_FAIL'
export const MADICINE_COUNTRY_LIST_RESET = 'MADICINE_COUNTRY_LIST_RESET'

export const TESTIMONIAL_LIST_REQUEST = 'TESTIMONIAL_LIST_REQUEST'
export const TESTIMONIAL_LIST_SUCCESS = 'TESTIMONIAL_LIST_SUCCESS'
export const TESTIMONIAL_LIST_FAIL = 'TESTIMONIAL_LIST_FAIL'
export const TESTIMONIAL_LIST_RESET = 'TESTIMONIAL_LIST_RESET'

export const COUNTRY_DETAILS_REQUEST = 'COUNTRY_DETAILS_REQUEST'
export const COUNTRY_DETAILS_SUCCESS = 'COUNTRY_DETAILS_SUCCESS'
export const COUNTRY_DETAILS_FAIL = 'COUNTRY_DETAILS_FAIL'
export const COUNTRY_DETAILS_RESET = 'COUNTRY_DETAILS_RESET'