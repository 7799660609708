import React, { useEffect, useState } from 'react'
import {Tabs, Tab, Input, Link, Button, Card, CardBody, CardHeader, Spinner} from "@nextui-org/react";import { MdAccessTime } from "react-icons/md";
import { FaRegCircleUser } from "react-icons/fa6";
import {Divider} from "@nextui-org/react";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, getKeyValue} from "@nextui-org/react";
import { getCourseDetails, getCourseList } from '../actions/courseActions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import parse from 'html-react-parser';
import { Skeleton } from "@nextui-org/react";
import uk from '../assets/ukQualification.png'
import eu from '../assets/euQ.png' 
import  wes  from '../assets/WES.png'
import  ugc  from '../assets/UGC.png'
import  of  from '../assets/Ofqual.svg'


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { EffectFlip, Autoplay, Navigation } from 'swiper/modules';
import { usePDF } from 'react-to-pdf';


import {Breadcrumbs, BreadcrumbItem} from "@nextui-org/react";
import { FaWhatsapp } from 'react-icons/fa';

const downloadFile = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // Set the download attribute with the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };
  

const CourseScreen = () => {

    const {courseSlug} = useParams()

    const dispatch = useDispatch()
    const history = useNavigate()
    const location = useLocation()

    const courseDetails = useSelector(state => state.courseDetails)
    const { error, loading, course } = courseDetails

    const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});

    useEffect(() => {
        window.scroll(0,0);
      }, [location]);

    useEffect(() => {
        if (courseSlug) {
            dispatch(getCourseDetails(courseSlug))
        }
    }, [courseSlug])
    
    const handleDownload = () => {
        if(course){
            downloadFile(course.brochure, 'file.pdf');
        }
      };
    
  return (
    <div ref={targetRef} className='w-full h-fit '>
        {
            loading?
            <div className='h-fit w-full flex justify-start mt-[100px]'>
                <Spinner size="md" />
              </div>:
            course ?
            <section className='relative overflow-hidden pb-12'>
                <img src={course.image} alt='' className='h-[200px] w-full object-cover relative -z-40 hidden lg:flex' />
                <div className='absolute top-0 h-[200px] w-full bg-black/75 z-0 flex flex-col items-center justify-center'>
                    <Breadcrumbs 
                        classNames={{
                            list: "",
                        }}
                        itemClasses={{
                            item: "text-white/60 text-center data-[current=true]:text-white",
                            separator: "text-white/40 text-center",
                        }}
                    className='text-3xl w-fit font-medium  mx-auto'>
                        <BreadcrumbItem className='text-3xl max-w-full' ><p className='text-sm text-center md:text-2xl capitalize font-bold'>{course.faculty.name}</p></BreadcrumbItem>
                        <BreadcrumbItem className='text-3xl max-w-full' ><p className='text-sm text-center md:text-2xl capitalize font-bold'>Course</p></BreadcrumbItem><br/>
                        <BreadcrumbItem className='text-3xl max-w-full' ><p className='text-sm text-center md:text-2xl capitalize font-bold'>{course.name}</p></BreadcrumbItem>
                    </Breadcrumbs>
                </div>
                <div className='w-full h-fit bg-white shadow-[0px_4px_25px_rgba(0,0,0,0.05)]  relative z-10'>
                    <div className='w-full h-fit max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] grid grid-cols-1 md:grid-cols-5 gap-4 sm:p-4 lg:p-8'>
                        <div className='w-full h-full md:col-span-3 flex flex-col gap-12'>
                            <div className='w-full h-full md:col-span-3 flex flex-col gap-8'>
                                <img src={course.image} alt='' className='h-[250px] w-full object-cover relative z-0' />
                                <div className='flex flex-col gap-6 px-6 lg:px-0'>
                                    <Breadcrumbs 
                                        classNames={{
                                            list: "",
                                        }}
                                        itemClasses={{
                                            item: "text-black/60 text-center data-[current=true]:text-black",
                                            separator: "text-black/40 text-center",
                                        }}
                                    className='text-3xl font-medium  max-w-fit mx-auto md:hidden'>
                                        <BreadcrumbItem className='text-3xl ' ><p className='text-sm text-center md:text-2xl capitalize font-bold'>{course.faculty.name}</p></BreadcrumbItem>
                                        <BreadcrumbItem className='text-3xl ' ><p className='text-sm text-center md:text-2xl capitalize font-bold'>Course</p></BreadcrumbItem><br/>
                                        <BreadcrumbItem className='text-3xl ' ><p className='text-sm text-center md:text-2xl capitalize font-bold'>{course.name}</p></BreadcrumbItem>
                                    </Breadcrumbs>
                                    <p className='text-3xl font-bold'>{course.name}</p>
                                    <div className='flex flex-col sm:flex-row flex-wrap justify-between'>
                                        <div className='flex flex-col'>
                                            <p className='text-xs opacity-50'>Class on</p>
                                            <p className='text-sm lg:text-xs font-medium capitalize'>{course.class_on}</p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-xs opacity-50'>Faculty</p>
                                            <p className='text-sm lg:text-xs font-medium capitalize'>{course.faculty.name}</p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-xs opacity-50'>Awarding body</p>
                                            <p className='text-sm lg:text-xs font-medium capitalize'>{course.qualification.name}</p>
                                        </div>

                                        <div className='flex flex-col'>
                                            <p className='text-xs opacity-50'>Duration</p>
                                            <p className='text-sm lg:text-xs font-medium capitalize'>{course.duration}</p>
                                        </div>

                                    </div>
                                </div>
                                <div className='h-[2px] w-full bg-gray-100'>

                                </div>
                                <div className='w-full h-fit px-6 lg:px-0'>
                                    <Tabs size='md' aria-label="Options" 
                                    classNames={{
                                        tabList: "bg-transparent",
                                        cursor: "bg-[#DA0C0C] rounded-none text-semibold",
                                        tab: "",
                                        tabContent: "group-data-[selected=true]:text-[white]"
                                    }}>
                                        <Tab key="overview" title="Overview">
                                            <div className='h-fit flex flex-col gap-4 text-sm text-left p-6 border-[1px] border-black/20'>
                                                <div className='flex flex-col gap-4 text-sm'>
                                                    {parse(course.overview)}
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab key="requirements" title="Requirements">
                                            <div className='h-fit flex flex-col gap-4 text-sm text-left p-6 border-[1px] border-black/20'>
                                                <div className='flex flex-col gap-4 text-sm'>
                                                    {parse(course.requirements)}
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab key="units" title="Units">
                                            <div className='h-fit flex flex-col gap-4 text-sm text-left p-6 border-[1px] border-black/20'>
                                                {parse(course.units)}
                                                {/* <Table aria-label="Example static collection table">
                                                    <TableHeader>
                                                        <TableColumn>Module</TableColumn>
                                                        <TableColumn>Level</TableColumn>
                                                        <TableColumn>Credit</TableColumn>
                                                    </TableHeader>
                                                    <TableBody>
                                                        {
                                                            courseListLoading?
                                                            '':
                                                            course && course.units?
                                                            course.units.map(i => (
                                                                <TableRow key={i.id}>
                                                                    <TableCell>{i.name}</TableCell>
                                                                    <TableCell>{i.level.name}</TableCell>
                                                                    <TableCell>{i.credit_count}</TableCell>
                                                                </TableRow>
                                                            ))
                                                            :
                                                            ""
                                                        }
                                                    </TableBody>
                                                </Table> */}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            
                            </div>  
                        </div>
                        
                    

                        <div className='h-fit w-full md:max-w-[400px] px-7 lg:px-0 md:col-span-2'>
                            <div className='w-full h-full  flex flex-col gap-4 border-[1px] border-[#DA0C0C] border-opacity-25 p-8'>
                                <p className='font-medium'>Courses Category</p>
                                <div className=' flex gap-2'>
                                    <div className='h-1 w-4 rounded-full bg-red-600'></div>
                                    <div className='h-1 w-6 rounded-full bg-red-600'></div>
                                </div>

                                <div className='flex w-full flex-col gap-2 text-sm'>
                                    <div className='flex w-full items-center justify-between'>
                                        <p>Level</p>
                                        <Button className='' size='sm' variant='solid'>{course.programme.name}</Button>
                                    </div>

                                    <div className='flex w-full items-center justify-between'>
                                        <p>Duration:</p>
                                        <Button className='' size='sm' variant='solid'>{course.duration}</Button>
                                    </div>

                                    <div className='flex w-full items-center justify-between'>
                                        <p>Course credit:</p>
                                        <Button className='' size='sm' variant='solid'>{course.course_credit}</Button>
                                    </div>

                                    <div className='flex w-full items-center justify-between'>
                                        <p>Qualification Approval:</p>
                                        <Button className='' size='sm' variant='solid'>Endorsed</Button>
                                    </div>
                                </div>
                                <img src={course.qualification.image} alt='' className='w-full h-fit bg-cover' />
                                
                                <p className='text-xl font-bold text-[#DA0C0C] text-center' >Qualification Awarded by "{course.qualification.name}"</p>
                            
                                <div className='h-[1px] w-full bg-[#da0c0c] bg-opacity-25'>

                                </div>
                                <div className='flex flex-col gap-4 bg-white px-8 py-8 border-[1px] border-[#DA0C0C] border-opacity-25'>
                                    
                                    <div className=' bg-[#DA0C0C] relative'>
                                        <Skeleton className='text-[#DA0C0C] bg-[#DA0C0C]'>
                                            <img src={uk} alt='' className='h-[50px] w-full object-contain'/>
                                        </Skeleton>
                                        {
                                            course.name.includes('EU') ?
                                            <img src={eu} alt='' className='h-[50px] w-full object-contain absolute top-0'/>:
                                            <img src={uk} alt='' className='h-[50px] w-full object-contain absolute top-0'/>
                                        }
                                    </div>
                                    {
                                        course.card?
                                        <div className='max-w-full'>
                                        {
                                            course.card.slug=='crt'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-2xl font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='fd'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-2xl font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='dip'? 
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                                                                                
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='hc-120'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>Level 4</p>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>120 credits</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='hc'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <img src={wes} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                            <img src={of} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='hd'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <img src={wes} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                            <img src={of} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='hd-120'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>Level 5</p>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>120 credits</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='hd-240'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>Level 4/5</p>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>240 credits</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='gd-120' ?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>Level 6</p>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>120 credits</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='gd' ?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <img src={wes} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                            <img src={of} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='bd'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <img src={wes} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                            <img src={ugc}  alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='pgd-120'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>Level 7</p>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <p className='font-semibold'>120 credits</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='pgd'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <img src={wes} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                            <img src={of} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='mst'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <img src={wes} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                            <img src={ugc} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='mst-top'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                            
                                                        
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        <div className='flex gap-3'>
                                                        <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                                <img src={wes} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                            <div className='bg-white text-black capitalize h-fit py-4 px-4 flex items-center justify-center'>
                                                            <img src={ugc} alt='' className='h-12 w-12 object-contain '/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            course.card.slug=='dr'?
                                            <Swiper
                                                effect={'flip'}
                                                grabCursor={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[EffectFlip, Autoplay]}
                                            >
                                                <SwiperSlide>
                                                    <div className='min-h-[150px] flex flex-col items-center justify-center bg-[#DA0C0C] gap-2'>
                                                        <p  className='text-lg px-2 font-bold text-white uppercase text-center'>
                                                            {course.programme.name}
                                                        </p>
                                                        
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className='flex flex-col gap-1 justify-center bg-[#DA0C0C] min-h-[150px] p-4'>
                                                
                                                        <p className='text-3xl font-bold text-white mx-auto text-center'>Scholarship Available</p>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>:
                                            ''
                                        }
                                    </div>:
                                    ''
                                    }
                                    <div className=' flex items-center justify-center animate-pulse'>
                                        <img src='https://i.pinimg.com/originals/e7/d1/99/e7d199bc42eeeb001962c6ed63d8b098.gif' alt='' className='h-20 w-h-20' />
                                        <p className='text-lg font-bold text-center '>+94 76 677 8810</p>
                                    </div>
                                    
                                    
                                </div>
                                <Button to={course.image} download={true} 
                                // onClick={() => toPDF()} 
                                onClick={handleDownload}
                                variant='solid' color='danger' className='w-full bg-blue-950 text-white' >
                                    <p className='text-2xl font-black'>E</p>
                                    <p>Brouchers</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>:
            ''
        }
        
    </div>
  )
}

export default CourseScreen