import React, { useEffect } from 'react'
import logo from '../assets/Logo.png'
import logo1 from '../assets/Logo1.png'
import { CiLinkedin } from "react-icons/ci";
import logoAD from '../assets/logoAD.png'
import {Button} from "@nextui-org/react";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook, FaPhoneAlt, FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourseList } from '../actions/courseActions';
import _, { map } from 'lodash';
import { getEuCountryList, getMainCountryList, getMedicineCountryList } from '../actions/abroadActions';
import { IoMdMail } from 'react-icons/io';
import { IoLocationSharp } from 'react-icons/io5';

const Footer = () => {
    const {course} = useParams()

    const dispatch = useDispatch()
    const history = useNavigate()
    const location = useLocation()

    const courseList = useSelector(state => state.courseList)
    const { error: courseListError, loading: courseListLoading, courses } = courseList

    const mainCountryList = useSelector(state => state.mainCountryList)
    const { error:mainCountryListError, loading:mainCountryListLoaoding, countries:mainCountries } = mainCountryList

    const euCountryList = useSelector(state => state.euCountryList)
    const { error:euCountryListError, loading:euCountryListLoaoding, countries:euCountries } = euCountryList

    const medicineCountryList = useSelector(state => state.medicineCountryList)
    const { error:medicineCountryListError, loading:medicineCountryListLoaoding, countries:medicineCountries } = medicineCountryList

    const testimonialList = useSelector(state => state.testimonialList)
    const { error: testimonialListError, loading: testimonialListLoading, testimonials } = testimonialList

  useEffect(() => {
    if (!mainCountries){
      dispatch(getMainCountryList())
    }

    if (!euCountries){
      dispatch(getEuCountryList())
    }

    if (!medicineCountries){
      dispatch(getMedicineCountryList())
    }

  }, [dispatch, mainCountries, euCountries, medicineCountries])


    const levelList = useSelector(state => state.levelList)
    const { error:levelListError, loading:levelListLoading, levels } = levelList

    useEffect(() => {
        if(!courses){
          dispatch(getCourseList('faculties', 'programes', 'qualifications', 'credits'))
        }
    }, [dispatch, courses])

    useEffect(() => {
        window.scroll(0,0);
      }, [location]);
  return (
    <section className='h-fit w-full bg-blue-950 text-white py-12'>
      <div className='h-full w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col md:flex-row items-center gap-8'>
        <div className='flex flex-col gap-12'>
            <div className=''>
                <img src={logo1} alt='' className='h-[100px] w-fit object-contain' />
            </div>
            <div className='flex flex-col gap-2'>

              <div className='border-[1px] border-white/50 p-4'>
                  <></>
                  <div className='h-fit w-full flex flex-col gap-1'>
                    <p className='text-white/50 uppercase text-xs'>Office</p>
                    <p className='text-white/75 text-xs'>562/15, Lower Bagathale Road, Colombo 03</p>
                  </div>
              </div>

              <div className='border-[1px] border-white/50 p-4'>
                  <></>
                  <div className='h-fit w-full flex flex-col gap-1'>
                    <p className='text-white/50 uppercase text-xs'>Email</p>
                    <p className='text-white/75 text-xs'>info@britishqual.lk</p>
                  </div>
              </div>

              <div className='border-[1px] border-white/50 p-4'>
                  <></>
                  <div className='h-fit w-full flex flex-col gap-1'>
                    <p className='text-white/50 uppercase text-xs'>Contact</p>
                    <p className='text-white/75 text-xs'>+94766778810</p>
                  </div>
              </div>

            </div>
        </div>
        <div className='w-full flex flex-wrap justify-center  gap-8'>
          <div className='flex flex-col gap-8'>
            <p className='font-semibold '>PROGRAMMES</p>
            <div className='flex flex-col gap-4 px-4'>
            {
                          levelListLoading?
                            <Link className='text-xs opacity-50'>Level 3 (Foundation)</Link>
                            :
                            levels?
                            levels.slice().reverse().map(i => (
                              <Link to={`/footer/courses/faculties/${i.slug}/qualifications/credits`} className='text-xs opacity-50'>
                                {i.name}
                              </Link>
                            ))
                            :
                            ''
                        }
        
            </div>
          </div>

          <div className='flex flex-col gap-8'>
            <p className='font-semibold '>QUICK LINKS</p>
            <div className='flex flex-col gap-4 px-4'>
              <p className='text-xs opacity-50'>ABOUT US</p>
              <p className='text-xs opacity-50'>APPLY ONLINE</p>
              <p className='text-xs opacity-50'>STUDENT PORTAL</p>
              <p className='text-xs opacity-50'>CERTIFICATE VERIFICATION</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer