import React, { useEffect, useState } from 'react'
import {Input, Popover, PopoverContent, PopoverTrigger, Skeleton, navbar} from "@nextui-org/react";
import { VscSymbolKeyword, VscVerifiedFilled } from "react-icons/vsc";
import {Button} from "@nextui-org/button";
import { IoSearch } from "react-icons/io5";
import { AiFillSignal } from "react-icons/ai";
import { BsBookmarksFill } from "react-icons/bs";
import { Faqualification, FaHandHoldingUsd, FaSearchLocation, FaWhatsapp } from "react-icons/fa";
import {Select, SelectItem} from "@nextui-org/react";
import { MdOutlineAdsClick } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import { FaIdCard } from "react-icons/fa";
import { MdLibraryBooks } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoArrowForwardOutline } from "react-icons/io5";
import _ from 'lodash';
import { TypeAnimation } from 'react-type-animation';
import { FiSearch } from "react-icons/fi";
import  apply  from '../assets/apply.png'
import  verification  from '../assets/verification.png'
import  sp  from '../assets/sp.png'
import  es  from '../assets/es.png'
import  mobile  from '../assets/mobile.jpg'
import {Tooltip} from "@nextui-org/react";
import ReactWhatsapp from 'react-whatsapp';
import parse from 'html-react-parser';
import YouTube from 'react-youtube';
import { format } from 'date-fns';
import ugc from '../assets/UGC.png'
import wes from '../assets/WES.png'
import inquiry from '../assets/inquiry.svg'
import ofqual from '../assets/Ofqual.svg'

import h2 from '../assets/h2.jpg'
import sa1 from '../assets/sa1.jpg'
import sa2 from '../assets/sa2.jpg'

import BANNER01 from '../assets/BANNER-01.jpg'
import BANNER02 from '../assets/BANNER-02.jpg'
import BANNER03 from '../assets/BANNER-03.jpg'

import one from '../assets/1.jpg'
import two from '../assets/2.jpg'
import three from '../assets/3.jpg'
import four from '../assets/4.jpg'
import five from '../assets/5.jpg'
import six from '../assets/6.jpg'

import home from '../assets/home.jpg'
import online from '../assets/online.jpg'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import {  Autoplay, FreeMode } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getpopularCourseList, getLevelList, getPopularpopularCourseList, getPopularCourseList, getOurQualificationList, getEventList } from '../actions/courseActions';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';
import { getTestimonialList } from '../actions/abroadActions';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import logo from '../assets/Logo.png'

const HomeScreen = () => {
    const [keyword, setKeyword] = useState('')
    const [faculty, setFaculty] = useState('faculties')
    const [programe, setPrograme] = useState('programes')
    const [qualification, setQualification] = useState('qualifications')
    const [credit, setCredit] = useState('credits')

    const [value, setValue] = useState(new Date());
    const [events, setEvents] = useState([
      { date: new Date(2024, 6, 7), event: 'anniversary' },
      { date: new Date(2024, 7, 13), event: 'anniversary' },
    ]);



    const onChange = (nextValue) => {
      setValue(nextValue);
    };

    const tileContent = ({ date, view }) => {
      const event = events.find(e => e.date.toDateString() === date.toDateString());
      return event && view === 'month' ? <p>{event.event}</p> : null;
    };

    const dispatch = useDispatch()
    const history = useNavigate()
    const location = useLocation()

    const eventList = useSelector(state => state.eventList)
    const { error: eventListError, loading: eventListLoading, eventse } = eventList

    const levelList = useSelector(state => state.levelList)
    const { error, loading, levels } = levelList

    const testimonialList = useSelector(state => state.testimonialList)
    const { error: testimonialListError, loading: testimonialListLoading, testimonials } = testimonialList

    const popularCourseList = useSelector(state => state.popularCourseList)
    const { error: popularCourseListError, loading: popularCourseListLoading, courses } = popularCourseList

    const facultyList = useSelector(state => state.facultyList)
    const { error:facultyListError, loading:facultyListLoading, faculties } = facultyList

    const ourQualificationList = useSelector(state => state.ourQualificationList)
    const { error:ourQualificationListError, loading:ourQualificationListLoading, qualifications } = ourQualificationList
    
    useEffect(() => {
      if(!courses){
        dispatch(getPopularCourseList())
      }

      

      if(!qualifications){
        dispatch(getOurQualificationList())
      }

      if(!eventse){
        dispatch(getEventList('home')) 
      }
            
    }, [dispatch, courses, testimonials, qualifications, eventse])


    useEffect(() => {
      dispatch(getTestimonialList('home'))
    }, [dispatch])
    

    const opts = {
      height: '200',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls:0,
      },
    };

    // Filter courses by qualification == "othm"
    const filteredCourses = courses ? courses.filter(course => course.qualification && course.qualification != 'othm-uk-qualification') : []

    // Group filtered courses by their program using lodash
    const groupedCourses = _.groupBy(filteredCourses, course => course.programme.name)


    // const submitHandler = () => {
    //   history(`${faculty}/${programe}/${qualification}/${credit}`)
    // } 

    useEffect(() => {
      window.scroll(0,0);
    }, [location]);
    

  return (
    <div className='w-full flex flex-col gap-16'>
      
      <section className='lg:h-[400px] w-full relative overflow-hidden'>

        <div className='lg:h-[400px] w-full object-cover object-bottom relative z-10'>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[ Autoplay]}
            className="h-fit w-full"
            autoplay
          >
            <SwiperSlide className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
              <img src={BANNER01} alt='' className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover object-center'/>
            </SwiperSlide>

            <SwiperSlide className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
              <img src={BANNER02} alt='' className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover object-center'/>
            </SwiperSlide> 

            <SwiperSlide className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
              <img src={BANNER03} alt='' className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover object-center'/>
            </SwiperSlide>
            
          </Swiper>
        </div>
        <div className='h-full w-full flex items-center bg-gradient-to-tr from-blue-950 to-blue-950/25 absolute top-0 z-30'>
        <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[ Autoplay]}
            className="h-fit w-full flex items-center"
            autoplay
          >
            <SwiperSlide className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
            <div className='h-full w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col gap-2 justify-center'>
              <div className=' flex flex-col gap-2 max-w-[500px]'>
                <p className=' text-sm md:text-2xl  text-white uppercase'>Reach you career</p>
                <p className='font-black text-2xl md:text-5xl text-white uppercase'>Learn online at your own convenience</p>
                <div className='mt-4 flex gap-2'>
                  <Button className='h-[35px] md:h-[50px] w-fit border-white border-2 bg-transparent font-bold text-sm md:text-lg text-white' radius='none'>
                    Register
                  </Button>
                  <ReactWhatsapp number="+94766778810" message="Hello, Can I get more info." className=' flex items-center justify-center px-4 gap-2 h-[35px] md:h-[50px] w-fit bg-red-600 font-bold text-sm md:text-lg text-white' radius='none'>
                    <FaWhatsapp className='text-xl' />
                    <p>Contact us</p>
                  </ReactWhatsapp>
                </div>
              </div>
            </div>
            </SwiperSlide>
            <SwiperSlide className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
            <div className='h-full w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col gap-2 justify-center'>
              <div className=' flex flex-col gap-2 max-w-[500px]'>
                <p className=' text-sm md:text-2xl  text-white uppercase'>Low fee, high quality degrees and diplomas</p>
                <p className='font-black text-2xl md:text-5xl text-white uppercase'>Affordable Excellence</p>
                <div className='mt-4 flex gap-2'>
                  <Button className='h-[35px] md:h-[50px] w-fit border-white border-2 bg-transparent font-bold text-sm md:text-lg text-white' radius='none'>
                    Register
                  </Button>
                  <ReactWhatsapp number="+94766778810" message="Hello, Can I get more info." className=' flex items-center justify-center px-4 gap-2 h-[35px] md:h-[50px] w-fit bg-red-600 font-bold text-sm md:text-lg text-white' radius='none'>
                    <FaWhatsapp className='text-xl' />
                    <p>Contact us</p>
                  </ReactWhatsapp>
                </div>
              </div>
            </div>
            </SwiperSlide>

            <SwiperSlide className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
            <div className='h-full w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col gap-2 justify-center'>
              <div className=' flex flex-col gap-2 max-w-[500px]'>
                <p className=' text-sm md:text-2xl  text-white uppercase'></p>
                <p className='font-black text-2xl md:text-5xl text-white uppercase'>Accredited & Online Degrees from the uk with excellent fees</p>
                <div className='mt-4 flex gap-2'>
                  <Button className='h-[35px] md:h-[50px] w-fit border-white border-2 bg-transparent font-bold text-sm md:text-lg text-white' radius='none'>
                    Register
                  </Button>
                  <ReactWhatsapp number="+94766778810" message="Hello, Can I get more info." className=' flex items-center justify-center px-4 gap-2 h-[35px] md:h-[50px] w-fit bg-red-600 font-bold text-sm md:text-lg text-white' radius='none'>
                    <FaWhatsapp className='text-xl' />
                    <p>Contact us</p>
                  </ReactWhatsapp>
                </div>
              </div>
            </div>
            </SwiperSlide>

         
          </Swiper>
           
        </div>
      </section>

      {/* <section className='w-full relative overflow-hidden '>
        <div className='h-fit w-full max-w-[1024px] mx-auto relative pt-[20px]'>
          <form className='bg-white shadow-[0px_5px_25px_rgba(0,0,0,0.05)] h-fit w-full z-20 mt-auto  px-6 md:px-12 py-8 flex flex-col gap-4 rounded-[8px]'>
              <div className='flex flex-row  gap-6 items-center justify-between'>
                <div className='w-full max-w-[500px]'>
                  <p className='text-xl font-bold text-[#DA0C0C] text-left'>Search Course</p>
                  <p className='text-xs text-gray-500'>Search And Filter our courses</p>
                </div>
                <div>
                  <Link to={`courses/${faculty}/${programe}/${qualification}/${credit}`} color='' className="flex bg-[#DA0C0C] text-xs lg:text-sm text-white px-4 md:px-5 py-2 md:py-3 rounded-full w-fit">
                    <p>Search</p>
                  </Link>
                </div>
              </div>
              <div className='flex flex-col md:flex-row items-center justify-between gap-2'>
                <div className='w-full grid grid-cols-2 md:flex lg:flex-row gap-2'>
                  <Select
                    variant={'flat'}
                    required
                    placeholder="Faculty"
                    className='w-full shadow-none rounded-none outline-none'
                    radius='sm'
                    size='md'
                    
                  >
                    {
                      loading?
                      '':
                      faculties?
                      faculties.map((i) => (
                        <SelectItem onClick={()=> setFaculty(`${i.slug}`)} value={i.slug} key={i.slug}>
                          {i.name}
                        </SelectItem>
                      ))
                      :
                      ''
                    }
                  </Select>

                  <Select
                    variant={'flat'}
                    required
                    placeholder="Programme"
                    className='w-full shadow-none rounded-none outline-none'
                    radius='sm'
                    size='md'
                    
                  >
                    {
                      loading?
                      '':
                      levels?
                      levels.map((i) => (
                        <SelectItem onClick={()=> setPrograme(`${i.slug}`)} value={i.slug} key={i.slug}>
                          {i.name}
                        </SelectItem>
                      ))
                      :
                      ''
                    }
                  </Select>
                </div>
                <div className='w-full flex lg:flex-row gap-2'>
                  <Select
                    variant={'flat'}
                    placeholder="Course qualification"
                    className='w-full shadow-none rounded-none outline-none'
                    radius='sm'
                    size='md'
                  >
                    {
                      ourQualificationListLoading?
                      '':
                      qualifications?
                      qualifications.map((i) => (
                        <SelectItem onClick={()=> setQualification(`${i.slug}`)} value={i.slug} key={i.slug}>
                          {i.name}
                        </SelectItem>
                      ))
                      :
                      ''
                    }
                  </Select>
                  <Select
                    variant={'flat'}
                    placeholder="Course credits"
                    className='w-full shadow-none rounded-none outline-none'
                    radius='sm'
                    size='md'
                  
                  >
                    {
                      popularCourseListLoading?
                      '':
                      courses?
                      _.uniqBy(courses, 'course_credit').map((i) => (
                        <SelectItem onClick={()=> setQualification(`${i.slug}`)} value={i.slug} key={i.slug}>
                          {i.course_credit}
                        </SelectItem>
                      ))
                      :
                      ''
                    }
                  </Select>
                </div>
              </div>
          </form>
        </div>
      </section> */}

      {/* <section className='h-fit w-full relative overflow-hidden py-2'>
        <div className='h-fit w-full max-w-[1024px] mx-auto grid grid-cols-2 md:flex justify-center px-6 lg:px-0 gap-2 lg:gap-6'>
            <Link to={'/application'} className='w-full max-w-1/2 md:max-w-1/3 lg:max-w-1/5  h-full md:h-48 flex flex-col items-center justify-center gap-4 bg-white text-black duration-200 cursor-pointer hover:text-[#DA0C0C] p-10 rounded-[8px] shadow-[0px_4px_25px_rgba(0,0,0,0.05)] hover:shadow-[0px_4px_25px_rgba(0,0,0,0.075)] '>
              <img src={apply}  alt='' className='w-16 object-contain' />
              <p className='text-xs lg:text-sm font-semibold px-2 text-center'>Apply</p>
            </Link>
            <Link to={'/verify'} className='w-full max-w-1/2 md:max-w-1/3 lg:max-w-1/5  h-full md:h-48 flex flex-col items-center justify-center gap-4 bg-white text-black duration-200 cursor-pointer hover:text-[#DA0C0C] p-10 rounded-[8px] shadow-[0px_4px_25px_rgba(0,0,0,0.05)] hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={verification}  alt='' className='w-16 object-contain' />
              <p className='text-xs lg:text-sm font-semibold px-2 text-center'>Verify Certificate</p>
            </Link>
            <Link to={'/student/portal'} className='w-full max-w-1/2 md:max-w-1/3 lg:max-w-1/5  h-full md:h-48 flex flex-col items-center justify-center gap-4 bg-white text-black duration-200 cursor-pointer hover:text-[#DA0C0C] p-10 rounded-[8px] shadow-[0px_4px_25px_rgba(0,0,0,0.05)] hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={sp}  alt='' className='w-16 object-contain' />
              <p className='text-xs lg:text-sm font-semibold px-2 text-center'>Student Portal</p>
            </Link>
            <Link to={'/e_library'} className='w-full max-w-1/2 md:max-w-1/3 lg:max-w-1/5  h-full md:h-48 flex flex-col items-center justify-center gap-4 bg-white text-black duration-200 cursor-pointer hover:text-[#DA0C0C] p-10 rounded-[8px] shadow-[0px_4px_25px_rgba(0,0,0,0.05)] hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={es}  alt='' className='w-16 object-contain' />
              <p className='text-xs lg:text-sm font-semibold px-2 text-center'>E library</p>
            </Link>             
        </div>
      </section> */}

<section className='h-fit w-full relative overflow-hidden py-2'>
        <div className='h-fit w-full max-w-[1024px] mx-auto grid grid-cols-2 md:flex justify-center px-6 lg:px-0 gap-2 lg:gap-6'>
            <Link to={'/application'} className='w-full max-w-1/2 md:max-w-1/3 lg:max-w-1/5  h-full md:h-48 flex flex-col items-center justify-center gap-4 bg-white text-black duration-200 cursor-pointer hover:text-[#DA0C0C] p-10 rounded-[8px] shadow-[0px_4px_25px_rgba(0,0,0,0.05)] hover:shadow-[0px_4px_25px_rgba(0,0,0,0.075)] '>
              <img src={apply}  alt='' className='w-16 object-contain' />
              <p className='text-xs lg:text-sm font-semibold px-2 text-center'>Apply</p>
            </Link>
            <Link to={'/verify'} className='w-full max-w-1/2 md:max-w-1/3 lg:max-w-1/5  h-full md:h-48 flex flex-col items-center justify-center gap-4 bg-white text-black duration-200 cursor-pointer hover:text-[#DA0C0C] p-10 rounded-[8px] shadow-[0px_4px_25px_rgba(0,0,0,0.05)] hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={verification}  alt='' className='w-16 object-contain' />
              <p className='text-xs lg:text-sm font-semibold px-2 text-center'>Verify Certificate</p>
            </Link>
            <Link to={'/student/portal'} className='w-full max-w-1/2 md:max-w-1/3 lg:max-w-1/5  h-full md:h-48 flex flex-col items-center justify-center gap-4 bg-white text-black duration-200 cursor-pointer hover:text-[#DA0C0C] p-10 rounded-[8px] shadow-[0px_4px_25px_rgba(0,0,0,0.05)] hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={sp}  alt='' className='w-16 object-contain' />
              <p className='text-xs lg:text-sm font-semibold px-2 text-center'>Student Portal</p>
            </Link>
            <Link to={'/e_library'} className='w-full max-w-1/2 md:max-w-1/3 lg:max-w-1/5  h-full md:h-48 flex flex-col items-center justify-center gap-4 bg-white text-black duration-200 cursor-pointer hover:text-[#DA0C0C] p-10 rounded-[8px] shadow-[0px_4px_25px_rgba(0,0,0,0.05)] hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={es}  alt='' className='w-16 object-contain' />
              <p className='text-xs lg:text-sm font-semibold px-2 text-center'>E library</p>
            </Link>             
        </div>
      </section>


      <section className='h-fit w-full relative overflow-hidden'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-2'>
            {/* <div className='flex flex-col'>
              <p className='uppercase text-xs text-center'>Faculty</p>
              <p className='text-2xl lg:text-4xl font-bold text-center'>Our Faculty</p>
            </div> */}
            <div className='w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-8 gap-4'>
              {
                facultyListLoading?
                ''
                :
                faculties?
                faculties.map(i => (
                  <Link to={`faculty/courses/${i.slug}/programes/awards/credits`} className='h-fit w-full bg-black relative overflow-hidden flex flex-col'>
                    <img src={i.image} alt='' className='h-[150px] w-full object-cover' />
                    <div className='w-full h-full hover:bg-black/30 duration-200 absolute z-30 top-0'>
                    </div>
                    <div className='h-full w-full py-2 px-2 bg-blue-950'>
                      <div className='h-fit w-full p-2 backdrop-blur-md rounded-[8px]'>
                        <p className='text-white font-medium capitalize text-left text-xs'>School of</p>
                        <p className='text-white font-bold capitalize text-left text-base'>{i.name}</p>
                      </div>
                    </div>
                  </Link>
                ))
                :
                ""
              }
            </div>
        </div>
      </section>

    

      <section className='h-fit w-full relative overflow-hidden px-8 bg-red-50 py-12 lg:py-16'>
        <div className='h-fit w-full max-w-[1024px] mx-auto justify-center gap-6 '>
          <div className='flex flex-col pb-8'>
            <p className='uppercase text-xs text-center'>Questions</p> 
            <p className='text-2xl lg:text-4xl font-bold text-center'>Why Learn with us?</p>
          </div>
          <div className='hidden w-full mx-auto lg:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center gap-4 place-content-evenly'>

            <Tooltip showArrow={true} content={
              <div className='p-4 max-w-[500px] '>
                Students can obtain memberships with relevant 
professional organizations, offering valuable networking opportunities, industry knowledge, 
and professional acknowledgment. These memberships aid in career growth and boost 
professional credibility.
              </div>
            } placement='bottom'>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/diploma_12608865.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                <p className='text-sm md:text-base font-bold text-center '>University Pathway Programmes</p>
              </div>
            </Tooltip>

            <Tooltip showArrow={true} content={
              <div className='p-4 max-w-[500px] '>
                Our digital platforms provide students have 24/7 access to
learning materials, support services, and communication tools. This flexibility enables learning and 
assistance to take place anytime, anywhere, and on any device, ensuring education is more
accessible and convenient.
              </div>
            } placement='bottom'>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/businessman_998412.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                <p className='text-sm md:text-base font-bold text-center '>Professional Development Resources</p>
              </div>
            </Tooltip>

            <Tooltip showArrow={true} content={
              <div className='p-4 max-w-[500px] '>
                We provide a variety of accredited programs that align with industry standards 
and are globally recognized. These courses guarantee students a high-quality education and 
credentials that boost their employability and career opportunities.
              </div>
            } placement='bottom'>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/policy.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                <p className='text-sm md:text-base font-bold text-center '>Accredited Courses</p>
              </div>
            </Tooltip>

            <Tooltip showArrow={true} content={
              <div className='p-4 max-w-[500px] '>
                Students can secure memberships with relevant professional 
organizations, granting access to networking opportunities, industry expertise, and professional 
recognition. These memberships foster career growth and strengthen professional credibility.
              </div>
            } placement='bottom'>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/membership.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                <p className='text-sm md:text-base font-bold text-center '>Professional Body Membership</p>
              </div>
            </Tooltip>

            <Tooltip showArrow={true} content={
              <div className='p-4 max-w-[500px] '>
                Our digital platforms provide students with 24/7 access to learning 
materials, support services, and communication tools. This flexibility enables students to learn and 
receive support anytime, anywhere, on any device, making education more convenient and 
accessible.
              </div>
            } placement='bottom'>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/24-hours.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                <p className='text-sm md:text-base font-bold text-center '>24/7 Connection. Anytime, Anywhere, Any Device</p>
              </div>
            </Tooltip>

            <Tooltip showArrow={true} content={
              <div className='p-4 max-w-[500px] '>
                 Experience an adaptable and cost effective path to 
education that suits your lifestyle. Our programs are tailored to fit your schedule, enabling you to 
achieve your goals without compromising your finances
              </div>
            } placement='bottom'>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/businessman_998412.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                <p className='text-sm md:text-base font-bold text-center '>Affordable Education, Tailored to You     </p>
              </div>
            </Tooltip>

            
          </div>

          <div className='w-full mx-auto grid grid-cols-2 lg:grid-cols-3 justify-center gap-4 place-content-evenly lg:hidden'>

            <Popover>
              <PopoverTrigger>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                        <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/diploma_12608865.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                        <p className='text-sm md:text-base font-bold text-center '>University Pathway Programmes</p>
                      </div>
              </PopoverTrigger>
              <PopoverContent>
                <div className='p-4 max-w-[500px] '>
                Students can obtain memberships with relevant 
professional organizations, offering valuable networking opportunities, industry knowledge, 
and professional acknowl

                  </div>
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                          <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/businessman_998412.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                          <p className='text-sm md:text-base font-bold text-center '>Professional Development Resources</p>
                        </div>
              </PopoverTrigger>
              <PopoverContent>
              <div className='p-4 max-w-[500px] '>
              Our digital platforms provide students have 24/7 access to
learning materials, support services, and communication tools. This flexibility enables learning and 
assistance to take place anytime, anywhere, and on any device, ensuring education is more
accessible and convenient
                  </div>
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                    <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/policy.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                    <p className='text-sm md:text-base font-bold text-center '>Accredited Courses</p>
                  </div>
                  </PopoverTrigger>
                  <PopoverContent>
              <div className='p-4 max-w-[500px] '>
              We provide a variety of accredited programs that align with industry standards 
and are globally recognized. These courses guarantee students a high-quality education and 
credentials that boost their employability and career opportunities.
              </div>
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                    <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/membership.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                    <p className='text-sm md:text-base font-bold text-center '>Professional Body Membership</p>
                  </div>
                  </PopoverTrigger>
                  <PopoverContent>
              <div className='p-4 max-w-[500px] '>
                Students can gain memberships to relevant professional bodies, providing networking opportunities, industry insights, and professional recognition. This membership supports career development and enhances professional credibility.
              </div>
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                    <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/24-hours.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                    <p className='text-sm md:text-base font-bold text-center '>Educational Counselling</p>
                  </div>
              </PopoverTrigger>
              <PopoverContent>
              <div className='p-4 max-w-[500px] '>
              Our digital platforms provide students with 24/7 access to learning 
materials, support services, and communication tools. This flexibility enables students to learn and 
receive support anytime, anywhere, on any device, making education more convenient and 
accessible.
              </div>
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger>
              <div className='h-[175px] w-full min-w-full border-[1px] border-gray-300 rounded-[8px] flex  flex-col items-center justify-center gap-2 px-8 mx-auto'>
                    <img src='https://d65ixtnmlqq6w.cloudfront.net/wp-content/uploads/2023/11/businessman_998412.webp' alt='' className='h-[40px] md:h-[80px] w-[40px] md:w-[80px] object-cover'/>
                    <p className='text-sm md:text-base font-bold text-center '>Affordable Education, Tailored to You             </p>
                  </div>
              </PopoverTrigger>
              <PopoverContent>
              <div className='p-4 max-w-[500px] '>
              Experience an adaptable and cost effective path to 
education that suits your lifestyle. Our programs are tailored to fit your schedule, enabling you to 
achieve your goals without compromising your finances.
              </div>
              </PopoverContent>
            </Popover>

            
          </div>
        </div>
      </section>
      
      

      <section className='h-fit w-full relative overflow-hidden '>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-2 flex flex-col md:flex-row items-center'>
              <div className='h-[300px] lg:h-[400px] w-full md:w-[300px] lg:w-[400px]'>
                <div className='h-[300px] lg:h-[400px] w-full min-w-[300px] lg:w-[400px] md:w-[300px] lg:min-w-[400px]'>
                  <img src={home} alt='' className='h-full w-full object-contain'/>
                </div>
              </div>
              <div className='h-fit w-full flex flex-col gap-2'>
                <p className='text-2xl font-bold'>Welcome to BritishQual </p>
                <div className=' flex gap-2'>
                  <div className='h-1 w-4 rounded-full bg-red-600'></div>
                  <div className='h-1 w-6 rounded-full bg-red-600'></div>
                </div>
                <p className='text-lg uppercase opacity-25'>Our mission is to empower learners to discover and cultivate their unique passions, understanding 
that individuals driven by genuine enthusiasm have the ability to create meaningful and positive 
change in the world.</p>
                <Button className='h-[50px] w-fit border-black border-2 bg-transparent font-bold text-lg text-black mt-4' radius='none'>
                  Register
                </Button>
              </div>
        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-2 grid grid-cols-3 md:grid-cols-3'>
              <div className='flex flex-col md:flex-row gap-4 items-center p-4 border-1 border-blue-950/15'>
                <img src={one} alt='' className='h-[75px] w-[75px] object-contain' />
                <p className='font-medium text-[10px] sm:text-xs md:text-sm'>Part Time and Flexible Studies </p>
              </div>

              <div className='flex flex-col md:flex-row gap-4 items-center p-4 border-1 border-blue-950/15'>
                <img src={two} alt='' className='h-[75px] w-[75px] object-contain' />
                <p className='font-medium text-[10px] sm:text-xs md:text-sm'>Completly Online </p>
              </div>

              <div className='flex flex-col md:flex-row gap-4 items-center p-4 border-1 border-blue-950/15'>
                <img src={three} alt='' className='h-[75px] w-[75px] object-contain' />
                <p className='font-medium text-[10px] sm:text-xs md:text-sm'>Acadamic Support From
                Expert With Lectures  </p>
              </div>

              <div className='flex flex-col md:flex-row gap-4 items-center p-4 border-1 border-blue-950/15'>
                <img src={four} alt='' className='h-[75px] w-[75px] object-contain' />
                <p className='font-medium text-[10px] sm:text-xs md:text-sm'>Learn Critical Skills that can be applied in your work space immideitely </p>
              </div>

              <div className='flex flex-col md:flex-row gap-4 items-center p-4 border-1 border-blue-950/15'>
                <img src={five} alt='' className='h-[75px] w-[75px] object-contain' />
                <p className='font-medium text-[10px] sm:text-xs md:text-sm'>Acadamic Support From
                Expert With Lectures </p>
              </div>

              <div className='flex flex-col md:flex-row gap-4 items-center p-4 border-1 border-blue-950/15'>
                <img src={six} alt='' className='h-[75px] w-[75px] object-contain' />
                <p className='font-medium text-[10px] sm:text-xs md:text-sm'>Acadamic Support From Expert With Lectures</p>
              </div>
        </div>
      </section>

{/* 
      <section className='h-fit w-full relative px-8'>
        <div className='h-fit w-full max-w-[1024px] mx-auto justify-center gap-6'>
            <div className='flex flex-col'>
              <p className='uppercase text-xs text-center'>Courses</p>
              <p className='text-2xl lg:text-4xl font-bold text-center'>Popular courses</p>
            </div>
            {
              popularCourseListLoading?
              '':
              courses?
              <div className='hidden xl:block'>
                <Swiper
                  slidesPerView={4}
                  spaceBetween={10}
                  
                  modules={[ Autoplay]}
                  className="w-full"
                  autoplay
                >
                  {
                    courses
                      .filter(course => course.qualification && course.qualification !== 'othm-uk-qualification' && course.popular === true)
                      .map(course => (
                        <SwiperSlide key={course.slug} className='bg-white mt-[24px] mb-[48px]'>
                          <Link to={`/courses/${course.slug}`} className='flex flex-col min-w-full gap-2 shadow-md'>
                            <div className='h-[150px] w-full overflow-hidden'>
                              <img src={course.image} alt='' className='h-[150px] hover:scale-105 duration-200 w-full object-cover border-[1px]' />
                            </div>
                            <div className='flex flex-col py-2 gap-2 p-4'>
                              <p className='font-bold capitalize'>{course.name}</p>
                            </div>
                            <div className='h-[2px] w-full px-4'>
                              <div className='h-[1px] w-full bg-gray-200'></div>
                            </div>
                            <div className='flex flex-wrap gap-1 p-4'>
                              <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                                online
                              </div>
                              <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                                {course.duration}
                              </div>
                            </div>
                          </Link>
                        </SwiperSlide>
                      ))
                  }
                  
                </Swiper>    
              </div>:
              ''
              }

            {
              popularCourseListLoading?
              '':
              courses?
              <div className='hidden lg:block xl:hidden'>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={10}
                  
                  modules={[ Autoplay]}
                  className="w-full"
                  autoplay
                >
                  {
                    courses
                      .filter(course => course.qualification && course.qualification !== 'othm-uk-qualification' && course.popular === true)
                      .map(course => (
                        <SwiperSlide key={course.slug} className='bg-white mt-[24px] mb-[48px]'>
                          <Link to={`/courses/${course.slug}`} className='flex flex-col min-w-full gap-2 shadow-md'>
                            <div className='h-[150px] w-full overflow-hidden'>
                              <img src={course.image} alt='' className='h-[150px] hover:scale-105 duration-200 w-full object-cover border-[1px]' />
                            </div>
                            <div className='flex flex-col py-2 gap-2 p-4'>
                              <p className='font-bold capitalize'>{course.name}</p>
                            </div>
                            <div className='h-[2px] w-full px-4'>
                              <div className='h-[1px] w-full bg-gray-200'></div>
                            </div>
                            <div className='flex flex-wrap gap-1 p-4'>
                              <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                                online
                              </div>
                              <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                                {course.duration}
                              </div>
                            </div>
                          </Link>
                        </SwiperSlide>
                      ))
                  }
                  
                </Swiper>    
              </div>:
              ''
            }
            

            {
              popularCourseListLoading?
              '':
              courses?
              <div className='hidden sm:block lg:hidden'>
                <Swiper
                  slidesPerView={2}
                  spaceBetween={10}
                  
                  modules={[ Autoplay]}
                  className="w-full"
                  autoplay
                >
                  {
                    courses
                      .filter(course => course.qualification && course.qualification !== 'othm-uk-qualification' && course.popular === true)
                      .map(course => (
                        <SwiperSlide key={course.slug} className='bg-white mt-[24px] mb-[48px]'>
                          <Link to={`/courses/${course.slug}`} className='flex flex-col min-w-full gap-2 shadow-md'>
                            <div className='h-[150px] w-full overflow-hidden'>
                              <img src={course.image} alt='' className='h-[150px] hover:scale-105 duration-200 w-full object-cover border-[1px]' />
                            </div>
                            <div className='flex flex-col py-2 gap-2 p-4'>
                              <p className='font-bold capitalize'>{course.name}</p>
                            </div>
                            <div className='h-[2px] w-full px-4'>
                              <div className='h-[1px] w-full bg-gray-200'></div>
                            </div>
                            <div className='flex flex-wrap gap-1 p-4'>
                              <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                                online
                              </div>
                              <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                                {course.duration}
                              </div>
                            </div>
                          </Link>
                        </SwiperSlide>
                      ))
                  }
                  
                </Swiper>    
              </div>:
              ''
            }
            

            {
              popularCourseListLoading?
              '':
              courses?
              <div className='block sm:hidden'>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  
                  modules={[ Autoplay]}
                  className="w-full"
                  autoplay
                >
                  {
                    courses
                      .filter(course => course.qualification && course.qualification !== 'othm-uk-qualification' && course.popular === true)
                      .map(course => (
                        <SwiperSlide key={course.slug} className='bg-white mt-[24px] mb-[48px]'>
                          <Link to={`/courses/${course.slug}`} className='flex flex-col min-w-full gap-2 shadow-md'>
                            <div className='h-[150px] w-full overflow-hidden'>
                              <img src={course.image} alt='' className='h-[150px] hover:scale-105 duration-200 w-full object-cover border-[1px]' />
                            </div>
                            <div className='flex flex-col py-2 gap-2 p-4'>
                              <p className='font-bold capitalize'>{course.name}</p>
                            </div>
                            <div className='h-[2px] w-full px-4'>
                              <div className='h-[1px] w-full bg-gray-200'></div>
                            </div>
                            <div className='flex flex-wrap gap-1 p-4'>
                              <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                                online
                              </div>
                              <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                                {course.duration}
                              </div>
                            </div>
                          </Link>
                        </SwiperSlide>
                      ))
                  }
                </Swiper>    
              </div>:
              ''
            }
        </div>
      </section> */}


      <section className='h-fit w-full relative hidden md:block'>
  <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-6'>
  {
  popularCourseListLoading ? '' :
  groupedCourses ? Object.keys(groupedCourses).reverse().map(programName => {
    const visibleCourses = groupedCourses[programName].filter(course => course.visible);

    // Only render if there are visible courses
    if (visibleCourses.length === 0) {
      return null; // Do not render the program section if there are no visible courses
    }

    return (
      <div key={programName} className='mb-8 flex flex-col gap-2'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-2xl lg:text-4xl font-bold text-left'>{programName}</h2>
          <div className='flex gap-2'>
            <div className='h-1 w-4 rounded-full bg-red-600'></div>
            <div className='h-1 w-6 rounded-full bg-red-600'></div>
          </div>
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          modules={[Autoplay]}
          className="w-full"
          autoplay
        >
          {
            visibleCourses.map(course => (
              <SwiperSlide key={course.slug} className='bg-white mt-[24px] mb-[48px]'>
                <Link to={`/courses/${course.slug}`} className='flex flex-col min-w-full gap-2 shadow-md'>
                  <div className='h-[150px] w-full overflow-hidden'>
                    <img src={course.image} alt='' className='h-[150px] hover:scale-105 duration-200 w-full object-cover border-[1px]' />
                  </div>
                  <div className='flex flex-col py-2 gap-2 p-4'>
                    <p className='font-bold capitalize'>{course.name}</p>
                  </div>
                  <div className='h-[2px] w-full px-4'>
                    <div className='h-[1px] w-full bg-gray-200'></div>
                  </div>
                  <div className='flex flex-wrap gap-1 p-4'>
                    <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                      online
                    </div>
                    <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                      {course.duration}
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    );
  }) : ''
}
  </div>
</section>


      <section className='h-fit w-full relative hidden sm:block md:hidden'>
          <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-6'>
              {/* <div className='flex flex-col'>
                  <p className='uppercase text-xs text-center'>Courses</p>
                  <p className='text-2xl lg:text-4xl font-bold text-center'>Popular OTHM Courses</p>
              </div> */}

{
  popularCourseListLoading ? '' :
  groupedCourses ? Object.keys(groupedCourses).reverse().map(programName => {
    const visibleCourses = groupedCourses[programName].filter(course => course.visible);

    // Only render if there are visible courses
    if (visibleCourses.length === 0) {
      return null; // Do not render the program section if there are no visible courses
    }

    return (
      <div key={programName} className='mb-8 flex flex-col gap-2'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-2xl lg:text-4xl font-bold text-left'>{programName}</h2>
          <div className='flex gap-2'>
            <div className='h-1 w-4 rounded-full bg-red-600'></div>
            <div className='h-1 w-6 rounded-full bg-red-600'></div>
          </div>
        </div>
        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          modules={[Autoplay]}
          className="w-full"
          autoplay
        >
          {
            visibleCourses.map(course => (
              <SwiperSlide key={course.slug} className='bg-white mt-[24px] mb-[48px]'>
                <Link to={`/courses/${course.slug}`} className='flex flex-col min-w-full gap-2 shadow-md'>
                  <div className='h-[150px] w-full overflow-hidden'>
                    <img src={course.image} alt='' className='h-[150px] hover:scale-105 duration-200 w-full object-cover border-[1px]' />
                  </div>
                  <div className='flex flex-col py-2 gap-2 p-4'>
                    <p className='font-bold capitalize'>{course.name}</p>
                  </div>
                  <div className='h-[2px] w-full px-4'>
                    <div className='h-[1px] w-full bg-gray-200'></div>
                  </div>
                  <div className='flex flex-wrap gap-1 p-4'>
                    <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                      online
                    </div>
                    <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                      {course.duration}
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    );
  }) : ''
}
          </div>
      </section>

      <section className='h-fit w-full relative sm:hidden'>
          <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-6'>
              {/* <div className='flex flex-col'>
                  <p className='uppercase text-xs text-center'>Courses</p>
                  <p className='text-2xl lg:text-4xl font-bold text-center'>Popular OTHM Courses</p>
              </div> */}

{
  popularCourseListLoading ? '' :
  groupedCourses ? Object.keys(groupedCourses).reverse().map(programName => {
    const visibleCourses = groupedCourses[programName].filter(course => course.visible);

    // Only render if there are visible courses
    if (visibleCourses.length === 0) {
      return null; // Do not render the program section if there are no visible courses
    }

    return (
      <div key={programName} className='mb-8 flex flex-col gap-2'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-2xl lg:text-4xl font-bold text-left'>{programName}</h2>
          <div className='flex gap-2'>
            <div className='h-1 w-4 rounded-full bg-red-600'></div>
            <div className='h-1 w-6 rounded-full bg-red-600'></div>
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          modules={[Autoplay]}
          className="w-full"
          autoplay
        >
          {
            visibleCourses.map(course => (
              <SwiperSlide key={course.slug} className='bg-white mt-[24px] mb-[48px]'>
                <Link to={`/courses/${course.slug}`} className='flex flex-col min-w-full gap-2 shadow-md'>
                  <div className='h-[150px] w-full overflow-hidden'>
                    <img src={course.image} alt='' className='h-[150px] hover:scale-105 duration-200 w-full object-cover border-[1px]' />
                  </div>
                  <div className='flex flex-col py-2 gap-2 p-4'>
                    <p className='font-bold capitalize'>{course.name}</p>
                  </div>
                  <div className='h-[2px] w-full px-4'>
                    <div className='h-[1px] w-full bg-gray-200'></div>
                  </div>
                  <div className='flex flex-wrap gap-1 p-4'>
                    <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                      online
                    </div>
                    <div className='flex text-xs text-gray-500 px-2 md:px-5 py-2 md:py-2 bg-gray-200 capitalize'>
                      {course.duration}
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    );
  }) : ''
}
          </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden py-8  bg-blue-950 text-white'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-8 flex flex-col-reverse md:flex-row-reverse items-center '>
              <div className='h-[300px] lg:h-[400px] w-full md:w-[300px] lg:w-[400px] '>
                <div className='h-[300px] lg:h-[400px] w-full min-w-[300px] lg:w-[400px] md:w-[300px] lg:min-w-[400px] '>
                  <img src={online} alt='' className='h-full w-full object-cover'/>
                </div>
              </div>
              <div className='h-fit w-full flex flex-col gap-2'>
                <p className='text-2xl font-bold'>Tailored Online Learning for Everyday Life </p>
                <div className=' flex gap-2'>
                  <div className='h-1 w-4 rounded-full bg-red-600'></div>
                  <div className='h-1 w-6 rounded-full bg-red-600'></div>
                </div>
                <p className='text-sm opacity-50'>Advance your creative journey without interrupting your life. Our programs are designed to help you discover inspiration that seamlessly integrates with your daily routine. </p>
                <Button className='h-[35px] md:h-[50px] w-fit border-white border-2 bg-transparent font-bold text-sm md:text-lg text-white mt-4' radius='none'>
                  MSBM FOR BUSINESS
                </Button>
              </div>
        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden bg-gray-50 pb-8'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-2 flex flex-col md:flex-row items-center'>
              <div className='h-[300px] lg:h-[400px] w-full md:w-[300px] lg:w-[400px] '>
                <div className='h-[300px] lg:h-[400px] w-full min-w-[300px] lg:w-[400px] md:w-[300px] lg:min-w-[400px] flex items-center justify-center'>
                  <img src={logo} alt='' className='h-full w-full scale-75 object-contain animate-pulse bg-white px-12'/>
                </div>
              </div>
              <div className='h-fit w-full flex flex-col gap-2'>
                <p className='text-2xl font-bold'>Search Courses</p>
                <div className=' flex gap-2'>
                  <div className='h-1 w-4 rounded-full bg-red-600'></div>
                  <div className='h-1 w-6 rounded-full bg-red-600'></div>
                </div>
                <div className='flex flex-col gap-4 mt-4'>
                  <Select 
                    placeholder='Select Faculty' 
                    className="h-fit w-full md:w-[400px] rounded-none "
                    size='md' 
                    radius='none'
                    variant='flat'
                  >
                    {
                      loading?
                      '':
                      faculties?
                      faculties.map((i) => (
                        <SelectItem onClick={()=> setFaculty(`${i.slug}`)} value={i.slug} key={i.slug}>
                          {i.name}
                        </SelectItem>
                      ))
                      :
                      ''
                    }
                  </Select>
                  <Select 
                    placeholder='Select Programe' 
                    className="h-fit w-full md:w-[400px] rounded-none "
                    size='md' 
                    radius='none'
                    variant='flat'
                  >
                    {
                      loading?
                      '':
                      levels?
                      levels.map((i) => (
                        <SelectItem onClick={()=> setPrograme(`${i.slug}`)} value={i.slug} key={i.slug}>
                          {i.name}
                        </SelectItem>
                      ))
                      :
                      ''
                    }
                  </Select>
                  <Select 
                    placeholder='Select Qualification' 
                    className="h-fit w-full md:w-[400px] rounded-none "
                    size='md' 
                    radius='none'
                    variant='flat'
                  >
                    {
                      ourQualificationListLoading
                        ? ''
                        : qualifications
                          ? qualifications
                              .filter(i => i.slug !== 'othm-uk-qualification') // Filter out specific slug
                              .map((i) => (
                                <SelectItem
                                  onClick={() => setQualification(`${i.slug}`)}
                                  value={i.slug}
                                  key={i.slug}
                                >
                                  {i.name}
                                </SelectItem>
                              ))
                          : ''
                    }
                  </Select>
                  <Select 
                    placeholder='Select Credits' 
                    className="h-fit w-full md:w-[400px] rounded-none "
                    size='md' 
                    radius='none'
                    variant='flat'
                  >
                    {
                      popularCourseListLoading?
                      '':
                      courses?
                      _.uniqBy(courses, 'course_credit').map((i) => (
                        <SelectItem onClick={()=> setQualification(`${i.slug}`)} value={i.slug} key={i.slug}>
                          {i.course_credit}
                        </SelectItem>
                      ))
                      :
                      ''
                    }
                  </Select>
                </div>
                <Link to={`courses/${faculty}/${programe}/${qualification}/${credit}`} className='h-[50px] flex items-center justify-center px-4 py-2 w-fit border-black border-2 bg-transparent font-bold text-lg text-black mt-4' radius='none'>
                  Search
                </Link>
              </div>
        </div>
      </section>
      <section className='h-fit w-full'>
          <img src={'https://www.lrnglobal.org/wp-content/uploads/2016/03/logo2.png'} alt='' className='max-w-[400px] mx-auto object-contain w-full' />
      </section>

      <section className='h-fit w-full'>
          <img src={mobile} alt='' className='object-contain w-full' />
      </section>

     


    </div>
  )
}

export default HomeScreen