import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {Accordion, AccordionItem} from "@nextui-org/accordion";
import {Tabs, Tab, Card, CardBody, CardHeader, Skeleton, Button} from "@nextui-org/react";
import au1 from '../assets/au1.jpg'
import {Breadcrumbs, BreadcrumbItem} from "@nextui-org/react";
import { FaEye, FaHome } from "react-icons/fa";
import home from '../assets/home.jpg'
import { FiTarget } from 'react-icons/fi';
import story from '../assets/story.jpg'

const AboutScreen = () => {
  const dispatch = useDispatch()
    const history = useNavigate()
    const location = useLocation()

    const [selected, setSelected] = React.useState("photos");


    useEffect(() => {
      window.scroll(0,0);
    }, [location]);
  return (
    <div className='h-fit w-full flex flex-col gap-12 pb-12'>
      <section className='h-fit w-full relative bg-black'>
        <img src={au1} alt='' className='h-[300px] w-full object-cover relative z-0 opacity-50' />
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-2'>
          <p className='text-2xl md:text-4xl text-white font-bold'>About us</p>
          <div className="flex flex-col flex-wrap gap-4">
            
              <Breadcrumbs 
                classNames={{
                  list: "bg-black  bg-opacity-50 text-white p-4",
                }}
                itemClasses={{
                  item: "text-white data-[current=true]:text-white",
                  separator: "text-white/40",
                }}
              key={'i'} variant={'solid'} >
                <BreadcrumbItem className='text-white'>
                  <FaHome className='text-red-600 text-xl' />
                  <p className='pl-1'>Home</p>
                </BreadcrumbItem>
                <BreadcrumbItem className='text-white'>
                  <div className='border-[1px] border-red-600 p-[3px] rounded-full'>

                  </div>
                  <p className='pl-1'>About</p>
                </BreadcrumbItem>
              </Breadcrumbs>
            
          </div>
        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden '>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-2 flex flex-col md:flex-row items-center'>
              <div className='h-[300px] lg:h-[400px] w-full md:w-[300px] lg:w-[400px]'>
                <div className='h-[300px] lg:h-[400px] w-full min-w-[300px] lg:w-[400px] md:w-[300px] lg:min-w-[400px]'>
                  <img src={home} alt='' className='h-full w-full object-contain'/>
                </div>
              </div>
              <div className='h-fit w-full flex flex-col gap-4'>
                <p className='text-3xl font-bold'>Specialises</p>
                <div className=' flex gap-2'>
                  <div className='h-1 w-4 rounded-full bg-red-600'></div>
                  <div className='h-1 w-6 rounded-full bg-red-600'></div>
                </div>
                <div className='flex flex-col gap-2 opacity-75'>
                  <p className=''> BritishQual offers a range of online courses, starting from Level 3 Foundation, Level 4 and 5 Higher Diploma, Level 6 Graduate Diploma, Level 7 Postgraduate Diploma in a variety of streams

                  </p>
                  <p>
                  BritishQual also specialise in a range of executive education and corporate training. All of these courses are offered using state-of-the-art equipment and the latest in blended learning, to ensure every student has the opportunity to succeed to his or her potential. </p>
                </div>
               
              </div>
        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden py-16'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center flex flex-col md:flex-row items-start gap-6'>
              <div className='py-8 px-12 cursor-pointer shadow-xl hover:shadow-2xl duration-300 flex flex-col gap-4 '>
                  <FiTarget className='text-8xl text-blue-400'/>
                  <p className='text-2xl font-bold'>Our mission</p>
                  <div className='flex flex-col gap-2'>
                    <p className='text-sm'>Taking affordable British education to the world.</p>
                    <p className='text-sm'>Our Mission is to give people the opportunity to transform their lives, so that they, in turn, will transform their Organisations and the Global Community as a whole. </p>
                  </div>
              </div>
              <div className='py-8 px-12 cursor-pointer shadow-xl hover:shadow-2xl duration-300 flex flex-col gap-4 '>
                  <FaEye className='text-8xl text-blue-400'/>
                  <p className='text-2xl font-bold'>Our mission</p>
                  <div className='flex flex-col gap-2'>
                    <p className='text-sm'>Through personalized education, research, and social responsibility, we aim to leave an indelible mark on the lives of those we touch. </p>
                  </div>
              </div>
        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden py-16'>
        <div className='h-fit w-full max-w-[800px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center flex flex-col gap-6'>
          <p className='text-4xl font-bold text-center'>Our Core Principles</p>

          <Accordion defaultExpandedKeys={["1","2","3","4","5"]} isCompact>
            <AccordionItem className='text-black/50 py-4' key="1" aria-label="Accordion 1" title={
              <p className='text-xl font-bold'>Embrace Respect</p>
            }>
              Cultivate an environment where consideration for others is paramount, fostering a culture of understanding and collaboration. 
            </AccordionItem>

            <AccordionItem className='text-black/50 py-4' key="2" aria-label="Accordion 2" title={
              <p className='text-xl font-bold'>Foster Ambitious Dreams</p>
            }>
              Encourage the pursuit of lofty aspirations, inspiring individuals to envision and strive for remarkable achievements. 
            </AccordionItem>

            <AccordionItem className='text-black/50 py-4' key="3" aria-label="Accordion 3" title={<p className='text-xl font-bold'>Ignite Passion</p>}>
              Fuel enthusiasm in all endeavors, as genuine passion is the driving force behind innovation and excellence. 
            </AccordionItem>

            <AccordionItem className='text-black/50 py-4' key="4" aria-label="Accordion 4" title={
              <p className='text-xl font-bold'>Provide Support</p>
            }>
              Create a supportive community where individuals uplift one another, fostering a sense of belonging and encouragement. 
            </AccordionItem>

            <AccordionItem className='text-black/50 py-4' key="5" aria-label="Accordion 5" title={
              <p className='text-xl font-bold'>Collaborative Learning</p>
            }>
              Promote a culture of shared knowledge and mutual growth, emphasizing the collective journey towards continuous learning and improvement. 
            </AccordionItem>
          </Accordion>


        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden py-16  bg-blue-950 text-white'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-16 flex flex-col-reverse md:flex-row-reverse items-center '>
              <div className='h-[300px] lg:h-[400px] w-full md:w-[300px] lg:w-[400px] '>
                <div className='h-[300px] lg:h-[400px] w-full min-w-[300px] lg:w-[400px] md:w-[300px] lg:min-w-[400px] '>
                  <img src={story} alt='' className='h-full w-full object-cover'/>
                </div>
              </div>
              <div className='h-fit w-full flex flex-col gap-4'>
                <p className='text-3xl font-bold'>Our Story</p>
                <div className=' flex gap-2'>
                  <div className='h-1 w-4 rounded-full bg-red-600'></div>
                  <div className='h-1 w-6 rounded-full bg-red-600'></div>
                </div>
                <p className='text-sm opacity-50'>BritishQual is dedicated to supporting vibrant and engaged learner communities. Established to cater to the needs of Higher Education Institutions and a saturated learner community, BritishQual recognizes the current demands and requirements essential for enhancing knowledge within communities, thereby supporting both local and international economies. </p>
                <p className='text-sm opacity-50'>Emphasizing the importance of high-quality education that is accessible, affordable, and flexible, BritishQual strives to achieve these goals by offering a variety of online courses through interactive learning platforms. These courses, taught by expert instructors, embody BritishQual's steadfast belief in accessibility, affordability, and flexibility. </p>
                <p className='text-sm opacity-50'>BritishQual serves as a beacon of hope for students aspiring to pursue their dreams, breaking free from traditional barriers to education. </p>
                <p className='text-sm opacity-50'>As a thriving institution, BritishQual continually pushes the boundaries of what is achievable in education. Its diverse and dynamic student body is characterized by individuals who are determined to succeed and make a positive impact on the world, aided by the integration of technology that propels them toward their goals. </p>
              </div>
        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden py-16'>
        <div className='h-fit w-full max-w-[800px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center flex flex-col gap-6'>
          <p className='text-4xl font-bold text-center'>Learning Model</p>

          <Accordion defaultExpandedKeys={["1","2","3","4","5"]} isCompact>
            <AccordionItem className='text-black/50 py-4' key="1" aria-label="Accordion 1" title={
              <p className='text-xl font-bold'>Integrated Learning Approach </p>
            }>
              BritishQual adopts a blended learning model that seamlessly combines online self-study through the BritishQual Online Study Portal with virtual classrooms and live webinars. By merging cutting-edge technology, high-quality curriculum, and innovative teaching methods, BritishQual provides an exceptional learning experience for its learners.  
            </AccordionItem>

            <AccordionItem className='text-black/50 py-4' key="2" aria-label="Accordion 2" title={
              <p className='text-xl font-bold'>Course Material </p>
            }>
              Industry experts in respective fields deliver the content for each course, placing a significant emphasis on practical aspects and case studies.  
            </AccordionItem>

            <AccordionItem className='text-black/50 py-4' key="3" aria-label="Accordion 3" title={<p className='text-xl font-bold'>Virtual Classroom</p>}>
            Weekly Virtual Classroom Sessions ensure that BritishQual students enjoy the benefits akin to a full-time program.  
            </AccordionItem>

            <AccordionItem className='text-black/50 py-4' key="4" aria-label="Accordion 4" title={
              <p className='text-xl font-bold'>E-Mentoring</p>
            }>
              A rapid and effective e-mentoring system enables students to address specific academic queries via email.  
            </AccordionItem>

            <AccordionItem className='text-black/50 py-4' key="5" aria-label="Accordion 5" title={
              <p className='text-xl font-bold'>Web-Based Support Services </p>
            }>
              BritishQual's Learning Management System (LMS) portal, powered by the world's most effective LMS developed by and for BritishQual, offers comprehensive web-based support services.  
            </AccordionItem>
          </Accordion>


        </div>
      </section>

    </div>
  )
}

export default AboutScreen