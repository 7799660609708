import React, { useEffect, useState } from 'react'
import {Input} from "@nextui-org/react";
import {Textarea} from "@nextui-org/input";
import { Button } from '@nextui-org/react'
import { FaHome, FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoHome, IoLocationSharp, IoPersonCircle } from "react-icons/io5";
import { RiSecurePaymentFill } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa6';
import { sendCU, sendCUMail } from '../actions/courseActions';
import cu1 from '../assets/cu1.jpg'
import cu2 from '../assets/cu2.jpg'
import {Breadcrumbs, BreadcrumbItem} from "@nextui-org/react";


const ContactScreen = () => {
  const dispatch = useDispatch()
    const history = useNavigate()
    const location = useLocation()

    const [email, setemail] = useState('')
  const [mNumber, setmNumber] = useState('')
  const [name, setname] = useState('')
  const [subject, setsubject] = useState('')
  const [discription, setdiscription] = useState('')

  const sendCU = useSelector(state => state.sendCU)
  const { error: sendCUError, loading: sendCULoading, success:CUSuccess } = sendCU

  useEffect(() => {
    window.scroll(0,0);
  }, [location]);

  const sendMail = () =>{
    if((name!='')&&(mNumber!='')&&(email!='')&&(subject!='')&&(discription!='')){
      dispatch(sendCUMail({ 
        "name": name,
        "mNumber": mNumber,
        "email": email,
        "subject": subject,
        "discription": discription,
    }))
    setname('')
    setmNumber('')
    setemail('')
    setsubject('')
    setdiscription('')
    }
  }
  
  useEffect(() => {
    window.scroll(0,0);
  }, [location]);

  return ( 
    <div className='flex flex-col gap-14 '>
      <section className='relative'>
          <div className='h-[300px] w-full object-cover relative -z-40'>
            <img src={cu2} alt='' className='h-[300px] w-full object-cover relative -z-50' />
            <div className='h-[300px] w-full object-cover absolute -z-10 flex flex-col top-0 gap-4 items-center justify-center bg-black/35 text-white'>
                <p className='text-2xl md:text-4xl text-white font-bold text-center'>Contact info</p>
                <div className="flex flex-col flex-wrap gap-4">
                  
                    <Breadcrumbs 
                      classNames={{
                        list: "bg-black  bg-opacity-50 text-white p-4",
                      }}
                      itemClasses={{
                        item: "text-white data-[current=true]:text-white",
                        separator: "text-white/40",
                      }}
                    key={'i'} variant={'solid'} >
                      <BreadcrumbItem className='text-white'>
                        <FaHome className='text-red-600 text-xl' />
                        <p className='pl-1'>Home</p>
                      </BreadcrumbItem>
                      <BreadcrumbItem className='text-white'>
                        <div className='border-[1px] border-red-600 p-[3px] rounded-full'>

                        </div>
                        <p className='pl-1'>About</p>
                      </BreadcrumbItem>
                    </Breadcrumbs>
                  
                </div>
            </div>       
          </div>
          <div className='w-full h-fit max-w-[1024px] mx-auto bg-white relative z-10 md:gap-4 pt-12 px-12 rounded-[16px]'>
            <section className='w-full relative'>
            <div className='h-fit w-full mx-auto px-6 flex flex-col items-start gap-8'>
              <div className='w-full flex flex-row gap-4 '>

                <section variant='light' className='border-[1px] w-full border-black/10 py-4 px-8 flex flex-col gap-6 rounded-2xl justify-center items-center'>
                  <Button isIconOnly variant='solid' className='bg-blue-950 text-white rounded-full p-4 h-[50px] w-[50px]' startContent={<FaPhoneAlt className='text-2xl'/>}></Button>
                  <div className='flex flex-col items-center text-center'>
                    <p className='font-bold capitalize text-xl text-center'>Call Us</p>
                    <div className='flex flex-col pt-2'>
                      <p className='text-black/50 text-center'>+94766778810 </p>
                    </div>
                  </div>
                </section>
                <section variant='light' className='border-[1px] w-full border-black/10 py-4 px-8 flex flex-col gap-6 rounded-2xl justify-center items-center'>
                  <Button isIconOnly variant='solid' className='bg-blue-950 text-white rounded-full p-4 h-[50px] w-[50px]' startContent={<IoMdMail className='text-2xl'/>}></Button>
                  <div className='flex flex-col items-center text-center'>
                    <p className='font-bold capitalize text-xl text-center'>Address</p>
                    <div className='flex flex-col pt-2'>
                      <p className='text-black/50 text-center'> 562/15, Lower Bagathale Road, Colombo 03 </p>
                    </div>
                  </div>
                </section>

                <section variant='light' className='border-[1px] w-full border-black/10 py-4 px-8 flex flex-col gap-6 rounded-2xl justify-center items-center'>
                  <Button isIconOnly variant='solid' className='bg-blue-950 text-white rounded-full p-4 h-[50px] w-[50px]' startContent={<IoLocationSharp  className='text-2xl'/>}></Button>
                  <div className='flex flex-col items-center text-center'>
                    <p className='font-bold capitalize text-xl text-center'>Email Us</p>
                    <div className='flex flex-col pt-2'>
                      <p className='text-black/50 text-center'>info@britishqual.lk</p>
                    </div>
                  </div>
                </section>
                
              </div>
              {/* <div className=''>
                <div className=''>
                  <p className='text-3xl font-bold capitalize'>send message</p>
                  {
                    
                    CUSuccess?
                    <p className='text-xs text-green-500 font-lg '>Message send succefully</p>:
                    <p className='text-xs text-red-500 font-lg '>All fields are required*</p>
                  }
                </div>
                <div className='flex flex-col gap-4 py-8'>
                  <div className=' flex gap-4'>
                    <Input 
                      type="text"
                      placeholder="Name"
                      labelPlacement="outside"
                      variant="flat"
                      startContent={
                        <IoMdMail className="text-lg text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      className='w-full shadow-none rounded-none'
                      radius='sm'
                      value={name} 
                            onChange={(e) => setname(e.target.value)}
                      size='md'
                      // endContent={
                      //   // <VscSymbolKeyword />
                      // }
                    />
                    <Input
                      type="email"
                      placeholder="Email Address"
                      labelPlacement="outside"
                      variant="flat"
                      value={email} 
                            onChange={(e) => setemail(e.target.value)}
                      startContent={
                        <IoPersonCircle className="text-lg text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      endContent={
                        <div className="pointer-events-none flex items-center">
                          <span className="text-default-400 text-small">@gmail.com</span>
                        </div>
                      }
                      className='w-full shadow-none rounded-none'
                      radius='sm'
                      size='md'
                      // endContent={
                      //   // <VscSymbolKeyword />
                      // }
                    />
                  </div>
                  <div className=' flex gap-4'>
                    <Input
                      type="number"
                      placeholder="Contact Number"
                      labelPlacement="outside"
                      variant="flat"
                      maxLength={10}
                      value={mNumber} 
                            onChange={(e) => setmNumber(e.target.value)}
                      startContent={
                        <div className="pointer-events-none flex items-center">
                          <span className="text-default-400 text-small">+94</span>
                        </div>
                      }
                      className='w-full shadow-none rounded-none'
                      radius='sm'
                      size='md'
                      // endContent={
                      //   // <VscSymbolKeyword />
                      // }
                    />
                    <Input
                      type="text"
                      placeholder="Subject"
                      labelPlacement="outside"
                      variant="flat"
                      value={subject} 
                            onChange={(e) => setsubject(e.target.value)}
                      className='w-full shadow-none rounded-none'
                      radius='sm'
                      size='md'
                      // endContent={
                      //   // <VscSymbolKeyword />
                      // }
                    />
                  </div>
                  <Textarea
                    isRequired
                    labelPlacement="outside"
                    placeholder="Enter your description"
                    className="w-full"
                    variant='flat'
                    value={discription} 
                            onChange={(e) => setdiscription(e.target.value)}
                  />
                  <Button onClick={sendMail} color='' className="hidden lg:flex bg-[#DA0C0C] text-white font-medium md:px-6 py-6 rounded-md">
                    <p>Submit</p>
                    <RiSecurePaymentFill  />
                  </Button>
                </div>
              </div> */}
            </div>
            </section>
          </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden pb-12'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] justify-center gap-2 flex flex-col md:flex-row items-center'>
              <div className='h-[300px] lg:h-[400px] w-full '>
                <div className='h-[300px] lg:h-[400px] w-full flex items-center justify-center'>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7921.827383236318!2d79.851515!3d6.900925!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2595d83f15555%3A0x1fc39e39b2fa8632!2sEuropean%20Nations%20Campus%20-%20ENC!5e0!3m2!1sen!2slk!4v1725464951434!5m2!1sen!2slk" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                </div>
              </div>
        </div>
      </section>
      
      
    </div>
  )
}

export default ContactScreen